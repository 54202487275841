import { createContext, useReducer, useContext, useEffect } from 'react'
import {UserContext} from './user'
import { useRouter } from 'next/router'
import axios from 'axios'

const initialState = {funnels: null, showHits: true, funnelIndex: 0, funnelNodes: null, funnelEdges: null, selectedElements: null, isLoading: false,}
const FunnelsContext = createContext(initialState)

const FunnelsProvider = ({children}) => {
  const router = useRouter()
  const {state: userState, disclosures} = useContext(UserContext)

  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      // flags
      case 'SET_IS_SAVING':
        return {...state, isSaving: action.value}
      case 'SET_ABOUT_TO_SAVE':
        return {...state, aboutToSave: action.value}
      case 'SET_LOADING':
        return {...state, isLoading: action.value}
      case 'SET_LOADED':
        return {...state, isLoaded: action.value}
      case 'SET_SHOW_HITS':
        return {...state, showHits: action.value}

      // funnel
      case 'SET_SELECTED_ELEMENTS':
        return {...state, selectedElements: action.value}
      case 'SET_FUNNEL_NODES':
        return {...state, funnelNodes: action.value}
        case 'SET_FUNNEL_EDGES':
        return {...state, funnelEdges: action.value}
      case 'SET_INSTANCE':
        return {...state, instance: action.value}

      // funnels
      case 'SET_FUNNELS':
        return {...state, funnels: action.value}
      case 'SET_FUNNEL_INDEX':
        return {...state, funnelIndex: action.value}
      default:
        throw new Error()
    }
  }, initialState)

  const newFunnel = async () => {
		let elements = []
		if (state.aboutToSave) {
			const instance = state.instance.toObject()
			elements = instance.elements
		}
		const { data } = await axios.post(`/api/funnels/add`, { elements }, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
		const funnels = [...state.funnels, { id: data.funnelId }]
		dispatch({ type: 'SET_FUNNELS', value: funnels })
		router.push(`/funnels/${data.funnelId}`, null, { shallow: true })

		const i = funnels.findIndex(f => f.id == data.funnelId)
		dispatch({ type: 'SET_FUNNEL_INDEX', value: i })

		dispatch({ type: 'SET_FUNNEL_NODES', value: [] })
		dispatch({ type: 'SET_FUNNEL_EDGES', value: [] })
		dispatch({ type: 'SET_ABOUT_TO_SAVE', value: false })
	}

  const toggleView = () => {
		dispatch({ type: 'SET_SHOW_HITS', value: !state.showHits })
	}

  const selectFunnel = (funnel) => {
		const i = state.funnels.findIndex((q) => q === funnel)
		dispatch({ type: 'SET_FUNNEL_INDEX', value: i })
		router.push(`/funnels/${state.funnels[i].id}`)
	}

  const saveFunnel = () => {
		disclosures.signupModalDisclosure.onOpen()
	}

  useEffect(() => {
		if (!state.isLoaded) {
			dispatch({ type: 'SET_LOADED', value: true })
		}
	}, [])

  return <FunnelsContext.Provider value={{state, dispatch, newFunnel, toggleView, selectFunnel, saveFunnel}}>{children}</FunnelsContext.Provider>
}

export { FunnelsProvider, FunnelsContext }