import {useEffect, useContext} from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { ChakraProvider } from '@chakra-ui/react'

import { FunnelsProvider } from '../store/funnels'
import { UserProvider, UserContext } from '../store/user'

import AnalyticsHead from '../components/head/AnalyticsHead'

import 'focus-visible/dist/focus-visible'

import '../styles/globals.css'
import '../styles/react-flow.css'

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const AppWithStore = ({ Component, pageProps }) => {
  const { dispatch: userDispatch } = useContext(UserContext)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token !== '' && token !== 'null') {
      userDispatch({ type: 'SET_USER_TOKEN', value: token })
    }
  }, [])
  
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ChakraProvider>
        <Component {...pageProps} />
      </ChakraProvider>
    </GoogleOAuthProvider>
  )
}

function MyApp({ Component, pageProps, router }) {

  useEffect(()=> {
    const rootElement = document.getElementById('__next')
    rootElement.style.position = router.pathname === '/' ?  'fixed' : 'none'
  },[router.pathname])

  return (
    <UserProvider>
        <AnalyticsHead/>
        <AppWithStore Component={Component} pageProps={pageProps}/>
    </UserProvider>
  )
}

export default MyApp
